<script>
  import { mapGetters, mapActions } from "vuex";

  export default {
    props: ["participant"],
    data() {
      return {
        formParticipants: {
          internalPicture: null,
          externalPicture: null,
          externalVideo: null,
        },
        datalispoints: [],
        statusNewPoint: false,
        pointValue: null,
        contestType: "position",
        statusPoints: false,
      };
    },
    methods: {
      ...mapActions("competitions", ["registerCompetitionParticipant", "updateCompetitionParticipant", "createCompetitionPoints", "getPointsDb", "getCompetitionParticipant"]),

      sendNewPoints(event) {
        event.preventDefault();
        if (this.pointValue > 0) {
          this.createCompetitionPoints({
            idContest: this.$route.params.id,
            formJson: {
              idParticipant: this.participant._id,
              type: this.contestType,
              value: this.pointValue,
            },
          }).then(async () => {
            await this.currentPoints();
            this.pointValue = 0;
          });
        }
      },
      validateParticipantInclude(id) {
        return this.participantsList.find((it) => it.user._id === id);
      },
      updateCurrent(elemnt) {
        if (elemnt?.internalPicture) {
          this.formParticipants.internalPicture = this.getCompetition?.typeContest !== "yearly" ? "" : elemnt.internalPicture;
        }
        if (elemnt?.externalPicture) {
          this.formParticipants.externalPicture = elemnt.externalPicture;
        }
        if (elemnt?.externalVideo) {
          this.formParticipants.externalVideo = elemnt.externalVideo;
        }
      },
      async sendParticipants() {
        const formData = {
          user: this.participant._id,
        };
        if (this.formParticipants.internalPicture) {
          formData.internalPicture = this.formParticipants.internalPicture;
        }
        if (this.formParticipants.externalPicture) {
          formData.externalPicture = this.formParticipants.externalPicture;
        }
        if (this.formParticipants.externalVideo) {
          formData.externalVideo = this.formParticipants.externalVideo;
        }

        if (this.validateParticipantInclude(this.participant._id)) {
          this.$alerts.dataSending({ title: "Actualizando" });
          let resp = await this.updateCompetitionParticipant({ userId: this.participant._id, newUser: formData, contestId: this.$route.params.id });
          if (resp) {
            this.$alerts.closeSwal();

            let { participantEdited } = resp;
            this.updateCurrent(participantEdited);
            this.$alerts.closeSwal();
          }
        } else {
          this.$alerts.dataSending({ title: "Actualizando" });

          let resp = this.registerCompetitionParticipant({ newUser: formData, contestId: this.$route.params.id });
          if (resp) {
            this.updateCurrent(resp);
            this.$alerts.closeSwal();
          }
          this.closeModal();
        }
      },

      closeModal() {
        this.$emit("close");
      },
      cancelModal() {
        this.$emit("calncel");
      },
      handleDrop(section) {
        alert(`Imagen cargada en la sección ${section}`);
      },
      openFileInput(index) {
        this.$refs["fileInput" + index].click();
      },
      handleFileChange(index, imgLabel) {
        const fileInput = this.$refs["fileInput" + index];
        const file = fileInput.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.formParticipants[imgLabel] = e.target.result;
          };
          reader.readAsDataURL(file);
        }
      },
      getDate(date) {
        const dateOptions = { day: "numeric", month: "long", year: "numeric" };
        const formattedDate = new Date(date).toLocaleDateString("es-CO", dateOptions);
        const formattedTime = new Date(date).toLocaleTimeString("es-CO", { hour: "numeric", minute: "numeric", hour12: true });
        return `${formattedDate} at ${formattedTime}`;
      },
      async currentPoints() {
        await this.getPointsDb({ _id: this.$route.params.id });
      },
      validateParticipantPoints(point) {
        return point.participant._id === this.participant._id ? point : null;
      },
    },
    computed: {
      ...mapGetters("competitions", ["getPoints", "getCompetition"]),
      imgParticipant() {
        return this.participant.picture;
      },
      addnewPoints() {
        this.statusNewPoint = true;
      },
      sendPoints() {
        this.statusNewPoint = true;
      },
      cancelPoints() {
        this.statusNewPoint = false;
        this.pointValue = 0;
        this.cancelModal();
      },
      nameToModal() {
        const { user, artisticName } = this.participant;
        return artisticName || user;
      },
      haveImage() {
        return this.formParticipants?.internalPicture;
      },
      showImageText() {
        return !this.formParticipants?.internalPicture;
      },
    },
    async mounted() {
      await this.currentPoints();
      const { participants } = await this.getCompetitionParticipant({ contestId: this.$route.params.id });
      this.participantsList = participants;
      const partic = this.validateParticipantInclude(this.participant._id);
      this.updateCurrent(partic);
      this.statusPoints = this.getCompetition?.typeScore === "tip" ? true : false;
    },
  };
</script>

<template>
  <div class="competitions__modalContainer">
    <div @click="closeModal" class="competitions__overlay"></div>
    <div class="modal-content competitions__modal-content">
      <div class="modal-header">
        <h2>{{ "DETALLE DEL PARTICIPANTE: " + nameToModal }}</h2>
        <span class="close" @click="closeModal" id="closeModalBtn"><iconic name="close" class="competitions__close"></iconic></span>
      </div>
      <div class="competitions__imageMessage">
        <iconic name="informationIcon"></iconic>
        <p>Para garantizar una correcta visualización de la participante, es requerido subir dos imágenes en los formatos especificados a continuación.</p>
      </div>
      <div class="modal-body">
        <div class="modal-body--content1">
          <div class="container imgone">
            <div class="container__title" aria-required="true">{{ getCompetition.typeContest !== "yearly" ? "vertical" : "horizontal" }}</div>
            <div class="container__group">
              <div
                ref="dropzone0"
                :class="`dropzone dropzoneMySpace ${getCompetition.typeContest !== 'yearly' ? 'dropzoneMySpace--monthly' : ''}`"
                :style="{ 'background-image': 'url(' + haveImage + ')' }"
                @click="() => openFileInput(0)">
                <p v-if="showImageText && getCompetition.typeContest === 'yearly'">Dimension: 1200 x 908</p>
                <p v-else-if="showImageText && getCompetition.typeContest !== 'yearly'">Dimension: 800 x 1422</p>
              </div>
              <input :ref="'fileInput' + 0" type="file" style="display: none" @change="() => handleFileChange(0, 'internalPicture')" />
            </div>
          </div>
          <div class="container" v-if="getCompetition.typeContest !== 'yearly'">
            <div class="container__title" aria-required="true">horizontal podio</div>
            <div class="container__group">
              <div ref="dropzone1" class="dropzone dropzoneMonthlyHorizontal" @click="() => openFileInput(1, 'externalPicture')">
                <p v-if="!formParticipants.externalPicture">Dimension: 1200 x 908</p>
                <img v-if="formParticipants.externalPicture" :src="formParticipants.externalPicture" alt="Uploaded" class="dropzone__img" />
              </div>
              <input :ref="'fileInput' + 1" type="file" style="display: none" @change="() => handleFileChange(1, 'externalPicture')" />
            </div>
          </div>
        </div>
        <div class="container" v-show="statusPoints">
          <div class="container__points">
            <p>Puntuacion</p>
            <div class="add__btn">
              <button type="button" @click="($event) => sendNewPoints($event)" class="createpoints__btns--save">Publicar</button>
              <div class="btnCreate">
                <iconic name="plus" @click.native="() => addnewPoints"></iconic>
              </div>
            </div>
          </div>
          <div class="container__points_result">
            <div class="points" v-for="(point, index) in getPoints" :key="index">
              <div class="points__card" v-if="validateParticipantPoints(point)">
                <div class="points__info">
                  <div class="points__li points_li--name">
                    <div class="points__li_avatarC">
                      <div class="points__li_avatarC--content">
                        <avatar class="points__li_avatarC__avatar" :user="point.participant"></avatar>
                      </div>
                      <p class="__name">
                        {{ point.participant.artisticName }}
                      </p>
                    </div>
                  </div>
                  <div class="points__li">
                    <span class="points__li points__li--date">{{ getDate(point.createdAt) }}</span>
                    <span class="points__li points__li--points">+ {{ point.value }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="points" v-if="statusNewPoint">
              <div class="points__card">
                <div class="points__info">
                  <div class="points__li points__li--name">
                    <div class="points__li_avatarC">
                      <div class="points__li_avatarC--content">
                        <avatar class="points__li_avatarC__avatar" :user="participant"></avatar>
                      </div>
                      <p class="__name">
                        {{ participant.name }}
                      </p>
                    </div>
                  </div>
                  <div class="points__li">
                    <span class="points__li points__li--points">
                      +
                      <input class="points__li--points--input" id="newpoints" type="number" placeholder="0" min="0" v-model="pointValue" name="newpoints" required />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer competitions__footer">
        <button type="button" @click="() => cancelPoints" class="create__btns--cancel">Cancelar</button>
        <button type="button" @click="() => sendParticipants()" class="create__btns--save">Guardar</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .points {
    &__li {
      &--date {
        border-right: 0.5px solid #ebebeb;
        margin-right: 5px;
      }

      &--points {
        color: $primary;

        &--input {
          height: 33px;
          width: 120px;
          padding: 5px;
          border: none;
          border-bottom: 0.5px solid #1e1e1e;
          background-color: #ececec;
          text-align: center;
          color: $primary;
          &::placeholder {
            color: $primary;
          }
        }
      }
    }

    &__info {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__card {
      margin-top: 5px;
      background-color: #ebebeb;
      padding: 5px 10px;
      border-radius: 5px;
    }

    &__li_avatarC {
      display: flex;
      align-items: center;

      .__name {
        margin-left: 10px;
      }

      &__avatar {
        width: 35px;
        height: 35px;
      }
    }
    @media (max-width: 650px) {
      &__info {
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
      }
      &__li--date {
        margin-left: 45px;
        font-size: 13px;
      }
    }
    @media (max-width: 425px) {
      &__li--date {
        font-size: 10px;
      }
    }
  }

  .modal-header {
    .close {
      cursor: pointer;
    }
  }

  .modal-body {
    &--content1 {
      display: block;

      @media (min-width: 1024px) {
        display: flex;
      }
    }

    .container__points {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-top: 0.5px solid #ebebeb;
      padding-top: 15px;
      &__points_result {
        margin-top: 10px;
      }
    }

    .add__btn {
      display: flex;
      text-align: center;
      align-items: center;
      .createpoints__btns--save {
        border-radius: 12px;
        border: none;
        margin-right: 10px;
        height: 22px;
        font-size: 14px;
        padding: 0 10px;
        color: #bd0909;
        background: none;

        &:hover {
          border-radius: 4px;
          border: 1px solid #bd0909;
          background: #bd0909;
          color: #fff;
        }
      }
      .btnCreate {
        width: 30px;
        height: 30px;
        border: none;
        cursor: pointer;
        color: #fff;
        border-radius: 50%;
        background-color: #bd0909;

        :nth-child(1) {
          margin-top: 3.5px;
        }
      }
    }

    .container {
      &__group {
        @include Flex();
      }

      .dropzone {
        width: 95%;
        height: 230px;
        background: #ebebeb;
        margin: 10px;
        border-radius: 13px;
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;

        &__img {
          border-radius: 13px;
          width: 100%;
          height: 100%;
        }

        &:hover {
          transform: scale(1.03);
          opacity: 0.99;
          transition: 0.7s;
        }
      }
      &__title {
        font-size: 16px;
        text-transform: capitalize;
        font-weight: bold;
        &[aria-required]::after {
          content: "*";
          color: $primary-color;
          margin-left: 3px;
        }
      }

      .dropzone.dropzoneMySpace {
        width: 50%;
        border-right: 1px solid #ebebeb;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        &--monthly {
          width: 48%;
        }
        .dropzone.dropzoneMonthlyHorizontal {
          width: 100%;
        }
      }
    }
  }

  .competitions {
    &__modalContainer {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      @include Flex();
      z-index: 150;
      background-color: var(--bg-overlay);
    }
    &__imageMessage {
      @include Flex();
      gap: 10px;
      margin: $space-16 0 0 $space-12;
    }

    &__overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
    &__close {
      font-size: 16px;
    }
    &__modal-content {
      max-width: 750px;
      width: 95%;

      .modal-header .close {
        cursor: pointer;
      }
    }

    &__footer {
      display: flex;
      justify-content: center;
      align-items: center;

      .create__btns--cancel {
        background: white;
        border-radius: 12px;
      }
      .create__btns--save {
        border-radius: 12px;
      }
    }

    &__modal {
      position: absolute;
      @include Flex(column, flex-start);
      z-index: 1;
      width: 90%;
      max-width: 400px;
      height: 400px;
      height: 300px;
      border-radius: 10px;
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.77);
    }

    &__imageModal {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }

    &__awardContainer {
      position: absolute;
      top: 1rem;
      @include Flex(row, space-between);
      padding: 0 1rem;
      width: 100%;
    }

    &__awardLogo {
      @include Flex();
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    &__bubbleModal {
      @include Flex();
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: rgba(189, 9, 9, 0.7);
      color: #fff;
      font-weight: 500;
      font-size: 15px;
      align-self: flex-start;
      transition: all 0.4s ease;

      &:hover {
        background-color: $primary;
        transform: scale(1.2);
      }
    }

    @media (min-width: 768px) {
      &__modal {
        max-width: 500px;
        min-height: 55%;
      }
    }

    @media (min-width: 1024px) {
      &__modal {
        max-width: 700px;
      }
    }
  }
</style>
